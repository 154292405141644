<template>
  <div class="student-manage" v-loading="pageLoading">
    <div class="student-manage-title">
      <div class="text">
        <span>学生管理</span>
        <div class="line"></div>
      </div>
    </div>
    <!-- 表单 -->
    <el-form
      style="margin-top: 3.17708vw"
      :model="searchForm"
      onsubmit="return false"
      :inline="true"
    >
      <el-row>
        <el-col :span="4">
          <el-form-item class="form-search-item">
            <el-input
              class="form-input"
              v-model="searchForm.studentInfo"
              placeholder="学生姓名/编号/身份证"
              clearable
            />
          </el-form-item>
        </el-col>
        <el-col :span="4">
          <el-form-item class="form-search-item">
            <el-input
              class="form-input"
              v-model="searchForm.parentsInfo"
              placeholder="家长姓名/手机号"
              clearable
            />
          </el-form-item>
        </el-col>
        <el-col :span="3">
          <el-form-item class="form-search-item">
            <el-select
              class="form-input"
              v-model="searchForm.schoolName"
              clearable
              placeholder="请选择学校"
            >
              <el-option
                v-for="item in schoolOptions"
                :key="item.value"
                :label="item.name"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="3">
          <el-form-item class="form-search-item">
            <el-select
              class="form-input"
              v-model="searchForm.status"
              clearable
              placeholder="请选择状态"
            >
              <el-option
                v-for="item in statusOptions"
                :key="item.value"
                :label="item.name"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="4">
          <el-form-item style="float: right">
            <el-button icon="el-icon-refresh-right" @click="resetFun"
              >重置</el-button
            >
          </el-form-item>
          <el-form-item style="float: right">
            <el-button type="primary" icon="el-icon-search" @click="searchFun"
              >查询</el-button
            >
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <el-form-item style="float: left">
            <el-button icon="el-icon-upload2" @click="exportFun"
              >导出</el-button
            >
          </el-form-item>
          <el-form-item style="float: left">
            <el-button
              icon="el-icon-delete-solid"
              @click="handleDelFun('multiple')"
              >批量删除</el-button
            >
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <div class="table-content">
      <l-table
        :columns="columns"
        :dataSource="tableData"
        :pagination="pageFlag"
        :ipagination="page"
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
        :loading="tableLoading"
        :showXh="false"
        :showCheckBox="true"
        @selection-change="handleSelectionChange"
        @select="handleSelectData"
        :autoHeight="true"
      >
        <el-table-column
          prop="studentName"
          label="姓名"
          align="center"
          slot="studentName"
        ></el-table-column>
        <el-table-column
          prop="nickName"
          label="昵称"
          align="center"
          slot="nickName"
        ></el-table-column>
        <el-table-column
          prop="englishName"
          label="英文名"
          align="center"
          slot="englishName"
        ></el-table-column>
        <el-table-column
          prop="sex"
          label="性别"
          align="center"
          slot="sex"
        ></el-table-column>
        <el-table-column
          prop="age"
          label="年龄"
          align="center"
          slot="age"
        ></el-table-column>
        <el-table-column
          prop="firstContactPerson"
          slot="firstContactPerson"
          label="第一联系人"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="firstContactPhone"
          slot="firstContactPhone"
          label="联系人手机号"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="status"
          label="离校状态"
          align="center"
          slot="status"
        ></el-table-column>
        <el-table-column
          prop="schoolName"
          slot="schoolName"
          label="学校"
          align="center"
        ></el-table-column>
        <el-table-column label="操作" slot="handle" align="center" width="180">
          <template slot-scope="scope">
            <span class="table-edit" @click="handleEdit(scope.row)">编辑</span>
            <span class="table-del" @click="handleDelFun('single', scope.row)"
              >删除
            </span>
            <span class="table-more-edit" @click="handleDetail(scope.row)">详情</span>
          </template>
        </el-table-column>
      </l-table>
    </div>
    <el-dialog
      :title="studentHandleDialogTitle"
      :visible.sync="studentHandleDialogVisible"
      :append-to-body="true"
      :destroy-on-close="true"
      @before-close="handleStudentHandleClose"
      @close="handleStudentHandleClose"
      width="50%"
    >
      <el-form
        class="dialog-form"
        :model="studentHandleDialogForm"
        ref="studentHandleDialogForm"
        label-width="6.25vw"
      >
        <el-row>
          <el-col :span="24">
            <el-form-item class="form-choose-item" label="第一步：">
              <el-button type="primary">下载编号</el-button>
              <div>下载班级编号，用于学生添加班级信息</div>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item class="form-choose-item" label="第二步：">
              <el-button type="primary">下载模板</el-button>
              <div>下载导入模板，用于批量填写需要导入的学生信息</div>
              <div>注意：红色标记为必填项，班级编号必须是第一步表内的编号</div>
              <div>使用office的excel编辑信息导入</div>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item
              class="form-choose-item"
              label="第三步："
            >
              <el-upload
                class="upload-demo"
                ref="upload"
                action="https://jsonplaceholder.typicode.com/posts/"
                :on-preview="handlePreview"
                :on-remove="handleRemove"
                :file-list="fileList"
                :auto-upload="false"
              >
                <el-button slot="trigger" size="small" type="primary"
                  >上传</el-button
                >
                <div slot="tip" class="el-upload__tip">
                  上传填写好的学生信息表
                </div>
              </el-upload>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item>
              <div class="export-div">
                <span>每个文件</span>
                <el-input
                  v-model="studentHandleDialogForm.exportNum"
                  :maxlength="50"
                ></el-input>
                <span>条</span>
              </div>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button :loading="buttonloading" @click="handleStudentHandleClose"
          >取 消</el-button
        >
        <el-button :loading="buttonloading" type="primary" @click="handleStudentHandleSubmit"
          >{{ studentHandleDialogTitle == '批量导入' ? '开始导入' : (studentHandleDialogTitle == '批量导出' ? '开始导出' : "") }}</el-button
        >
      </div>
    </el-dialog>
    <class-handle-dialog
      :classHandleDialogVisible.sync="classHandleDialogVisible"
      :classHandleDialogTitle="classHandleDialogTitle"
      :classHandleDialogType="classHandleDialogType"
      @refreshPage="refreshPage"
    ></class-handle-dialog>
  </div>
</template>
<script>
import LTable from "@/components/tableComponent/index.vue";
import ClassHandleDialog from "@/components/classHandle/index.vue";
export default {
  name: "studentManage",
  components: {
    LTable,
    ClassHandleDialog
  },
  data() {
    return {
      pageLoading: false, //页面loading
      page: {
        num: 1,
        size: 10,
        total: 0,
      }, //分页信息
      pageFlag: false, //是否显示分页
      tableData: [], //表格数据
      tableLoading: false, //表格loading
      selectData: [], //选中数据
      columns: [
        {
          label: "姓名",
          slotName: "studentName",
        },
        {
          label: "昵称",
          slotName: "nickName",
        },
        {
          label: "英文名",
          slotName: "englishName",
        },
        {
          label: "性别",
          slotName: "sex",
        },
        {
          label: "年龄",
          slotName: "age",
        },
        {
          label: "第一联系人",
          slotName: "firstContactPerson",
        },
        {
          label: "联系人手机号",
          slotName: "firstContactPhone",
        },
        {
          label: "离校状态",
          slotName: "status",
        },
        {
          label: "所在学校",
          slotName: "schoolName",
        },
        {
          label: "操作",
          slotName: "handle",
        },
      ],
      searchForm: {
        studentInfo: "",
        parentsInfo: "",
        schoolName: "",
        status: "",
      }, //搜索条件
      statusOptions: [], //状态下拉
      schoolOptions: [], //学校下拉
      sexOptions: [], //性别下拉
      buttonloading: false, //按钮loading

      classHandleDialogVisible: false, //批量升班/转班弹窗标识
      classHandleDialogTitle: "", //批量升班/转班弹窗标题
      classHandleDialogType: "", //批量升班/转班弹窗类型

      studentHandleDialogTitle: "", //批量导入/导出操作弹框标题
      studentHandleDialogVisible: false,//批量导入/导出操作弹框标识
      studentHandleDialogForm: {
        fileInfo: "",
        exportNum: "",
      }
    };
  },
  methods: {
    //获取列表信息
    getData() {},
    //查询
    searchFun() {
      this.page.num = 1;
      this.getData();
    },
    //重置
    resetFun() {
      this.searchForm = {
        studentInfo: "",
        parentsInfo: "",
        schoolName: "",
        status: "",
      };
      this.page.num = 1;
      this.getData();
    },
    //页码变化
    handleCurrentChange(data) {
      this.page.num = data;
      this.getData();
    },
    //每页条数变化
    handleSizeChange(data) {
      this.page.size = data;
      this.page.num = 1;
      this.getData();
    },
    //选中数据变化
    handleSelectionChange(arr) {
      console.log(arr);
    },
    //选中数据
    handleSelectData(selection, row) {
      console.log(selection);
      console.log(row);
    },
    //导出
    exportFun() {
      this.studentHandleDialogVisible = true;
      this.$nextTick(() => {
        this.studentHandleDialogTitle = "批量导出"
      });
    },
    //删除操作
    handleDelFun(type, row) {
      const h = this.$createElement;
      if (type == "multiple") {
        if (this.selectData.length == 0) {
          this.$message.warning("请选择至少一条数据");
          return;
        }
        this.$confirm(
          h("p", null, [
            h(
              "span",
              {
                style:
                  "color: rgba(0, 0, 0, 0.85);font-size: 16px;font-weight: 500;",
              },
              "确定要删除选中的学生？"
            ),
            h(
              "div",
              {
                style:
                  "color: rgba(0, 0, 0, 0.65);font-size: 14px;margin-top: 10px",
              },
              "是否确定删除选中的学生，删除后，将无法恢复。"
            ),
          ]),
          "提示",
          {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            iconClass: "el-icon-question",
            type: "warning",
            customClass: "warningMessage",
          }
        )
          .then(() => {
            
          })
          .catch(() => {});
      } else if (type == "single") {
        this.$confirm(
          h("p", null, [
            h(
              "span",
              {
                style:
                  "color: rgba(0, 0, 0, 0.85);font-size: 16px;font-weight: 500;",
              },
              "确定要删除该学生？"
            ),
            h(
              "div",
              {
                style:
                  "color: rgba(0, 0, 0, 0.65);font-size: 14px;margin-top: 10px",
              },
              "是否确定删除该学生，删除后，将无法恢复。"
            ),
          ]),
          "提示",
          {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            iconClass: "el-icon-question",
            type: "warning",
            customClass: "warningMessage",
          }
        )
          .then(() => {
            
          })
          .catch(() => {});
      }
    },
    //批量操作
    handleClass(type) {
      if (this.selectData.length == 0) {
        this.$message.warning("请选择至少一条数据");
        return;
      }
      this.classHandleDialogVisible = true;
      this.$nextTick(() => {
        if (type == "up") {
          this.classHandleDialogTitle = "批量升班";
        } else if (type == "turn") {
          this.classHandleDialogTitle = "批量转班";
        }
        this.classHandleDialogType = type;
      });
    },
    //编辑学生
    handleEdit(row){
      this.$router.push({ path: "/studentManage/edit", query: { handleType: 'edit', type: 'student', id: row.id } })
    },
    //批量导入导出弹窗关闭
    handleStudentHandleClose(){
      this.studentHandleDialogVisible = false;
      this.$nextTick(() => {
        this.studentHandleDialogForm = {
          fileInfo: "",
          exportNum: "",
        };
        this.studentHandleDialogTitle = ""
        this.buttonloading = false;
      });
    },
    //批量导入导出弹窗提交
    handleStudentHandleSubmit(){
      this.buttonloading = true;
      this.handleStudentHandleClose()
    },
    //批量操作回调
    refreshPage(data) {
      this.classHandleDialogVisible = false;
      this.$nextTick(() => {
        this.classHandleDialogTitle = "";
        this.classHandleDialogType = "";
        if (data) {
          this.page.num = 1;
          this.getData();
        }
      });
    },
  },
};
</script>
<style scoped lang="scss">
.student-manage {
  padding: 30px 25px 20px 25px;
  background-color: #ffffff;
  position: relative;
  .student-manage-title {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 60px;
    border-bottom: 1px solid rgba(112, 112, 112, 0.3);
    .text {
      width: 100%;
      height: 100%;
      margin-left: 24px;
      font-size: 20px;
      font-weight: bold;
      color: rgba(0, 0, 0, 0.85);
      position: relative;
      display: flex;
      align-items: center;
      .line {
        width: 24px;
        height: 3px;
        background-color: #597ef7;
        position: absolute;
        bottom: 0;
        left: 40px;
        transform: translateX(-50%);
      }
    }
  }
}
</style>